<template>
	<div class="notice">
		<!-- <div class="header">
			<div>专题专栏></div>
			<div class="current">专题赛事</div>
		</div> -->
		<!-- <div class="horizontalline"></div> -->

		<div class="title-box">
			<div class="block"></div>
			<div class="block-title">北京竞技场</div>
		</div>

		<div class="content">
			<div class="horizontalline"></div>
			<div style="display: flex;flex-wrap: wrap;">
				<div class="news_box" v-for="(item,index) in data" :key="index">
					<div class="news_item">
						<a @click="jump(item)">
							<img :src="item.img_url" v-if="item.img_url"/>
							<img src="../assets/image/placeholder.png" alt="" v-else>
							<h3>
								<div class="pic-title">
									{{item.name}}
								</div>
							</h3>
						</a>
					</div>
					<!-- <div class="horizontalline horizon" v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'specialsubject',
		components: {},
		data() {
			return {
				data: []
			}
		},
		created() {
			this.$store.commit("EXIT_STATIONID",'1005012')
			this.findList()
		},
		methods: {
			findList() {
				this.$api.bjSport(744,0).then(res => {
					this.data = res.data.data
				})
			},
			jump(item) {
				if(item.bind_theme == 0){
					window.open(item.link_address, '_blank');
				}else{
					this.$router.push({
						path: item.link_address,
						query: {
							logo:item.img_url,
							title:item.name,
							refresh: new Date().getTime()
						},
					})
				}
			}
		}
	}
</script>
<style  lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		margin-left: 63PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-bottom: 23PX;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.content {
		width: 1200PX;
		margin: 0 auto;
	}

	.horizontalline {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
	}

	.title-box {
		display: flex;
		margin-top: 28PX;
		align-items: center;
		margin-bottom: 21PX;
	}

	.block {
		width: 6PX;
		height: 30PX;
		background: #164B92;
	}

	.block-title {
		font-size: 26PX;
		font-weight: 500;
		color: #333333;
		margin-left: 18PX;
	}

	.specific {
		margin-left: 53PX;
		font-size: 15PX;
		color: #333333;
	}

	.specific ul li {
		display: flex;
		justify-content: space-between;
		margin-bottom: 23PX;
	}

	.rounddot {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.round {
		width: 7PX;
		height: 7PX;
		border-radius: 100%;
		background: #000;
		box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
		margin-right: 10PX;
	}

	.roundtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 1000PX;
	}

	.paging {
		margin-top: 81PX;
		margin-bottom: 132PX;
		display: flex;
		margin-left: 53PX;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	::v-deep .el-pager li.active {
		color: #003399;
	}
	
	.news_item {
		width: 362PX;
		margin-top: 28PX;
		margin-right: 30PX;
	}
	.news_box:nth-child(3n) .news_item{
		margin-right: 0;
	}
	
	.news_item img {
		width: 100%;
		height: 229PX;
	}
	
	.news_item h3 {
		font-size: 14PX;
		height: 54PX;
		line-height: 54PX;
		font-weight: 400;
		text-align: center;
		overflow: hidden;
	}
	
	.news_item:nth-child(3n) {
		margin-right: 0;
	}
</style>