import { render, staticRenderFns } from "./specialsubject.vue?vue&type=template&id=5f0afb34&scoped=true&"
import script from "./specialsubject.vue?vue&type=script&lang=js&"
export * from "./specialsubject.vue?vue&type=script&lang=js&"
import style0 from "./specialsubject.vue?vue&type=style&index=0&id=5f0afb34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f0afb34",
  null
  
)

export default component.exports